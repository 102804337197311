<script setup lang="ts">
import { ref } from 'vue'
import { useNavMenu } from '@/composables/useNavMenu'
import { useURLNav } from '@/composables/useURLNav'
import { useCheckBreakpoints } from '@/composables/useCheckBreakpoints'
import NavMobile from '@/components/navigation/NavMobile.vue'
import NavDesktop from '@/components/navigation/NavDesktop.vue'

const { isMobile } = useCheckBreakpoints()
const { socialMedia } = useNavMenu()
const { goToLink } = useURLNav()

const drawer = ref(false)
const showSocial = ref(false)
</script>
<template>
  <NavMobile
    v-if="isMobile"
    :drawer="drawer"
    @button-action="showSocial = !showSocial"
    @toggleDrawer="drawer = !drawer" />
  <v-app-bar color="primary" floating>
    <v-avatar class="ml-5 mr-10" size="48" image="/logo.png" color="base"></v-avatar>

    <NavDesktop
      v-if="!isMobile"
      @button-action="showSocial = !showSocial"/>
    <v-spacer></v-spacer>

    <v-app-bar-nav-icon
      v-if="isMobile"
      color="secondary"
      @click="drawer = true"
    ></v-app-bar-nav-icon>
  </v-app-bar>
  <!--Dialogo para conectar a redes sociales-->
  <v-dialog v-model="showSocial" width="auto">
    <v-card>
      <v-toolbar color="base" class="text-white" title="Siguenos en nuestras Redes"></v-toolbar>
      <v-card-text>
        <div class="text-h2 pa-12">
          <v-list v-for="item in socialMedia" :key="item.label">
            <v-list-item link color="secondary" @click="goToLink(item)">
              <v-icon left class="mr-2">
                {{ item.icon }}
              </v-icon>
              {{ item.label }}
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn variant="text" @click="showSocial = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--Fin del Dialog-->
</template>
