import { ref } from 'vue'
import pharusIcons  from '@/composables/useIcons'
import { useUserSession } from '@/composables/auth/useUserSession'

const menu = ref([])

export function useNavMenu() {
  const { user } = useUserSession()
  const menuItems = [
    { icon: pharusIcons.mdiHome, label: 'Inicio', link: '/' },
    { icon: pharusIcons.mdiAccount, label: 'Perfil', link: '/perfil' },
    { icon: pharusIcons.mdiAccount, label: 'Admin', link: '/admin' },
    { icon: pharusIcons.mdiBookmark, label: 'Favoritos', link: '/favoritos' },
    { icon: pharusIcons.mdiAccessPoint, label: 'Siguenos', link: 'Sociales' },
    { icon: pharusIcons.mdiAccountOff, label: 'Cerrar sesión', link: '/logout' }
  ]
  const navMenu = menuItems.filter(
    ({ label }) => label !== 'Admin' || user.isAdmin
  );
  const socialMedia = [
    {
      icon: pharusIcons.mdiFacebook,
      label: 'Facebook',
      url: 'https://www.facebook.com/profile.php?id=100086436683672'
    },
    {
      icon: pharusIcons.mdiInstagram,
      label: 'Instagram',
      url: 'https://www.instagram.com/pharushn/'
    },
    {
      icon: pharusIcons.mdiLinkedin,
      label: 'Linkedin',
      url: 'https://www.linkedin.com/company/pharushn/'
    }
  ]
  return { menuItems, socialMedia, navMenu }
}