<script setup>
import { mdiClose } from "@mdi/js"
import { useURLNav } from "@/composables/useURLNav"
import { useNavMenu } from '@/composables/useNavMenu'

const { navMenu } = useNavMenu()
const { goToLink } = useURLNav()

const props = defineProps({
  drawer: {
    type: Boolean,
  },
  modelValue: {
    type: Boolean,
  },
});
const emit = defineEmits(["toggleDrawer"]);
</script>
<template>
  <v-navigation-drawer
    v-navigation-drawer-if="props.drawer === true"
    :modelValue="props.drawer"
    location="right"
    color="primary"
    @update:modelValue="emit('toggleDrawer', false)"
    temporary
  >
    <v-list-item link color="secondary" @click="emit('toggleDrawer', false)">
      <v-icon left class="mr-2"> {{ mdiClose }} </v-icon>
      Cerrar
    </v-list-item>
    <v-list bg-color="primary" v-for="item in navMenu" :key="item.label">
      <v-list-item @click="item.link === 'Sociales' ? emit('buttonAction', true) : goToLink({ route: item.link })" >
        <v-icon color="base" left class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.label }}
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
