<script setup>
import { useURLNav } from '@/composables/useURLNav'
import { useNavMenu } from '@/composables/useNavMenu'

const { navMenu } = useNavMenu()
const { goToLink } = useURLNav()

const emit = defineEmits(['buttonAction'])
</script>
<template>
  <v-list bg-color="primary" v-for="item in navMenu" :key="item.label">
    <v-list-item @click="item.link === 'Sociales' ? emit('buttonAction', true) : goToLink({ route: item.link })" >
      <v-icon color="base" left class="mr-2">
        {{ item.icon }}
      </v-icon>
      {{ item.label }}
    </v-list-item>
  </v-list>
</template>